import React from "react";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import axiosInstance from "../../lib/apiconfig";
import Cookies from "js-cookie";

function TopbarComponent() {
  const navigate = useNavigate();
  const refreshtoken = Cookies.get("refresh");
  const data = {
    refresh_token: refreshtoken,
  };
  const handleSignOut = async () => {
    try {
      const response = await axiosInstance.post("logout/", data);
      if (response.status === 200) {
        Cookies.remove("access");
        Cookies.remove("refresh");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <div className="w-full">
      <div className="flex justify-between items-center px-6">
        <h1 className="text-2xl font-bold text-primary-foreground">Overview</h1>
        <div className="flex justify-between items-center gap-4 mr-1">
          <a
            href="https://api.taskplus.io/api/v1/download/desktopapp/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-primary-foreground text-primary px-6 py-2 rounded-sm hover:bg-blue-300">
              Download App
            </button>
          </a>
          {/* User dropdown */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="overflow-hidden rounded-full"
              >
                <FaUser className="" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-36">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <button onClick={handleSignOut}>Logout</button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export default TopbarComponent;
