import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { MdMoreHoriz } from "react-icons/md";
import axiosInstance from "../../lib/apiconfig";

function ListCompanyInvitesPage() {
  const STATUS_COLORS = {
    accepted: "text-green-700",
    sent: "text-purple-700",
    declined: "text-gray-700",
  };
  const [teamInvites, setTeamInvites] = useState([]);

  const getInvites = async () => {
    try {
      const response = await axiosInstance.get(`v0/list/invites/`);
      setTeamInvites(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const deleteInvite = async (id) => {
    try {
      await axiosInstance.delete(`v0/delete/invite/${id}`);
      getInvites();
    }
    catch (error) {
      console.error("Error deleting invite:", error);
    }
  };

  const formatTime = (time) => {
    return new Date(time).toLocaleString("en-IN");
  };

  useEffect(() => {
    getInvites();
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
      <div className="w-full">
        <div className="flex items-center py-4">
          <Input placeholder="Filter emails..." className="max-w-sm" />
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date Created</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Designation</TableHead>
                <TableHead>Inviter</TableHead>
                <TableHead>Inviter Name</TableHead>
                <TableHead>Inviter Email</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {teamInvites.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {item.created_at ? formatTime(item.created_at) : ""}
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.designation}</TableCell>
                  <TableCell>{item.inviter.company.name}</TableCell>
                  <TableCell>
                    {item.inviter.first_name} {item.inviter.last_name}
                  </TableCell>
                  <TableCell>{item.inviter.email}</TableCell>
                  <TableCell
                    className={`font-medium ${STATUS_COLORS[item.status]}`}
                  >
                    {item.status.toUpperCase()}
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                          <span className="sr-only">Open menu</span>
                          <MdMoreHoriz className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem>
                          <button
                            onClick={() =>
                              navigator.clipboard.writeText(item.uuid)
                            }
                          >
                            Copy Invite Code
                          </button>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="text-red-800">
                          <button onClick={() => deleteInvite(item.id)}>Delete Invite</button>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ListCompanyInvitesPage;
