import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../components/ui/table";
import axiosInstance from "../../lib/apiconfig";
import { AlertDeleteAdminAccountDialog } from "../../components/elements/alertmodel";

function UserProfilePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef();
  const [userProfile, setUserProfile] = useState({
    email: "",
    designation: "",
    company: {
      name: "",
    },
  });
  const [userDocuments, setUserDocuments] = useState([
    {
      document: "",
    },
  ]);

  const [uploadUserDocument, setUploadUserDocument] = useState({
    document: null,
  });

  const getUserProfile = async () => {
    try {
      const response = await axiosInstance.get(`data/user/employee/${id}/`);
      setUserProfile(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserDocuments = async () => {
    try {
      const response = await axiosInstance.get(`company/list/documents/${id}/`);
      setUserDocuments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserProfile = async () => {
    try {
      const response = await axiosInstance.patch(
        `update/user/employeeprofile/${id}/`,
        userProfile
      );
      if (response.status === 200) {
        alert("Profile updated successfully");
        getUserProfile();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadDocument = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("document", uploadUserDocument);

      const response = await axiosInstance.post(
        `company/upload/document/${id}/`,
        formData
      );
      if (response.status === 201) {
        alert("Document uploaded successfully");
        formRef.current.reset();
        getUserDocuments();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleConfirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(`delete/user/${id}/`);
      if (response.status === 204) {
        alert("User deleted successfully");
        navigate("/admin/list-users");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserProfile();
    getUserDocuments();
  }, []);
  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-4 p-4 md:p-8">
      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">Your Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first-name">First name</Label>
                <Input
                  id="first_name"
                  defaultValue={userProfile.first_name}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last-name">Last name</Label>
                <Input
                  id="last_name"
                  defaultValue={userProfile.last_name}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  defaultValue={userProfile.email}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="phone">Phone</Label>
                <Input
                  id="phone"
                  defaultValue={userProfile.phone}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="address">Address</Label>
              <Input
                id="address"
                defaultValue={userProfile.address}
                onChange={(e) =>
                  setUserProfile({
                    ...userProfile,
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="company">Company</Label>
                <Input
                  id="company"
                  defaultValue={userProfile.company.name}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="designation">Designation</Label>
                <Input
                  id="designation"
                  defaultValue={userProfile.designation}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      designation: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <button
            className="px-6 py-2 rounded bg-primary text-primary-foreground shadow"
            onClick={updateUserProfile}
          >
            Update
          </button>
        </CardFooter>
      </Card>

      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">Bank Details</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="bank-name">Bank Name</Label>
                <Input
                  id="bank_name"
                  defaultValue={userProfile.bank_name}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="branch-code">Branch Code</Label>
                <Input
                  id="branch_code"
                  defaultValue={userProfile.bank_brach_code}
                  readOnly
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="account-title">Account Title</Label>
                <Input
                  id="account_title"
                  defaultValue={userProfile.bank_account_title}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="account-number">Account Number</Label>
                <Input
                  id="account_number"
                  defaultValue={userProfile.bank_account_number}
                  readOnly
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="iban-code">IBAN</Label>
                <Input
                  id="iban_code"
                  defaultValue={userProfile.bank_account_iban}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="swift-code">SWIFT Code</Label>
                <Input
                  id="swift_code"
                  defaultValue={userProfile.bank_account_swift_code}
                  readOnly
                />
              </div>
            </div>
          </form>
        </CardContent>
      </Card>

      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">
            Company Agreements
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody className="border border-border">
              {userDocuments.map((document, index) => (
                <TableRow key={index}>
                  <TableCell className="w-3/4">
                    {document.document.split("/")[4]}
                  </TableCell>
                  <TableCell className="text-right w-1/4">
                    <button className="bg-primary/25 text-primary font-medium hadow px-6 py-2 rounded-sm hover:bg-primary/90 hover:text-white">
                      <a
                        href={`https://api.taskplus.io/api/v1/download/document/${document.id}/`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Download
                      </a>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <hr className="border border-primary/40 mt-6" />
          <form className="mt-8" ref={formRef}>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="document-upload">Upload Document</Label>
                <Input
                  id="document"
                  type="file"
                  onChange={(e) => setUploadUserDocument(e.target.files[0])}
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <Button onClick={uploadDocument}>Upload</Button>
        </CardFooter>
      </Card>
      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg text-destructive">
            Delete Account?
          </CardTitle>
        </CardHeader>
        <CardFooter className="justify-end">
          <AlertDeleteAdminAccountDialog
            open={showDeleteDialog}
            onOpenChange={setShowDeleteDialog}
            onConfirm={handleConfirmDelete} // Pass the confirmation handler
          />
        </CardFooter>
      </Card>
    </div>
  );
}

export default UserProfilePage;
