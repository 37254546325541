import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/layout/dashlayout";
import AdminDashboard from "./pages/admin/dashboard";
import PageNotFoundError from "./pages/404error";
import LoginPage from "./pages/auth/login";
import CompanyProfilePage from "./pages/admin/companyprofile";
import ListCompaniesPage from "./pages/admin/listcompanies";
import ListUsersPage from "./pages/admin/listusers";
import UserProfilePage from "./pages/admin/userprofile";
import UserAnalyticsPage from "./pages/admin/usereanalytics";
import ListCompanyInvitesPage from "./pages/admin/listinvites";
import { SuperProtectedRoutes } from "./components/context/protectedroute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFoundError />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />} />

        <Route element={<SuperProtectedRoutes />}>
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/list-companies" element={<ListCompaniesPage />} />
            <Route path="/list-users" element={<ListUsersPage />} />
            <Route
              path="/list-companyinvites"
              element={<ListCompanyInvitesPage />}
            />
            <Route
              path="/company-profile/:id"
              element={<CompanyProfilePage />}
            />
            <Route path="/user-profile/:id" element={<UserProfilePage />} />
            <Route path="user-analytics/:id" element={<UserAnalyticsPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
