import { MdOutlineDashboard } from "react-icons/md";
import { BsBuildingsFill } from "react-icons/bs";
import { FaUsers, FaUserPlus } from "react-icons/fa";
import { FcInvite } from "react-icons/fc";



export const SIDEBAR_NAV_REALTIME = [
    {
      title: "Overview",
      href: "/dashboard",
      icon: <MdOutlineDashboard />,
    },
  ];
  export const SIDEBAR_NAV_LISTUSERS = [
    {
      title: "Companies",
      href: "/list-companies",
      icon: <BsBuildingsFill />,
    },
    {
      title: "Users",
      href: "/list-users",
      icon: <FaUsers />,
    },
    {
      title: "Company Invites",
      href: "/list-companyinvites",
      icon: <FaUserPlus />,
    }
  ];
  
  export const SIDEBAR_NAV_LEAVEMNGT = [
    {
      title: "Apply Leave",
      href: "/applyleave",
      icon: "home",
    },
    {
      title: "Manage Leave",
      href: "/manageleave",
      icon: "info",
    },
  ];
  
  export const SIDEBAR_NAV_APPUSE = [
    {
      title: "Review Apps",
      href: "/reviewapps",
      icon: "home",
    },
    {
      title: "App History",
      href: "/apphistory",
      icon: "info",
    },
    {
      title: "App Summary",
      href: "/appsummary",
      icon: "info",
    },
  ];
  export const SIDEBAR_NAV_EMPLOYEEMANGT = [
    {
      title: "Manage Team",
      href: "/manageteam",
      icon: "home",
    },
    {
      title: "Manage Invites",
      href: "/manageinvites",
      icon: "info",
    },
  ];
  
  export const SIDEBAR_NAV_SETTINGS = [
    {
      title: "Settings",
      href: "/settings",
      icon: "home",
    },
    {
      title: "Billing",
      href: "/billing",
      icon: "info",
    },
  ];
  

  export const SIDEBAR_NAV_USER = [
    {
      title: "Overview",
      href: "/overview",
      icon: "home",
    },
    {
      title: "Screenshots",
      href: "/userscreenshots",
      icon: "info",
    },
    {
      title: "Apply Leave",
      href: "/leaveapplication",
      icon: "info",
    },
    {
      title: "Profile",
      href: "/myprofile",
      icon: "home",
    },
  ];