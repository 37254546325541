import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import axiosInstance from "../../lib/apiconfig";
import { useParams } from "react-router-dom";

function CompanyProfilePage() {
  const { id } = useParams();
  const [companyData, setCompanyData] = useState({
    name: "",
    website: "",
    email: "",
    phone: "",
    address: "",
  });
  const fetchData = async () => {
    try {
      const res = await axiosInstance.get(`v0/data/company/${id}`);
      setCompanyData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateData = async () => {
    try {
      const res = await axiosInstance.patch(
        "update/user/company/",
        companyData
      );
      if (res.status === 200) {
        alert("Company Data Updated");
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatTime = (time) => {
    return new Date(time).toLocaleString('en-IN');
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-4 p-4 md:p-8">
      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">
            Company Profile Settings
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="date-created">Unique Company ID</Label>
                <Input
                  id="date_created"
                  defaultValue={companyData.uuid ?? ""}
                  readOnly
                />
              </div>
            </div>
          <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="date-created">Date Created</Label>
                <Input
                  id="date_created"
                  defaultValue={companyData.created_at ? formatTime(companyData.created_at) : ""}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="company-website">Last Updated</Label>
                <Input
                  id="company_name"
                  defaultValue={companyData.created_at ? formatTime(companyData.updated_at) : ""}
                  readOnly
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="company-name">Company Name</Label>
                <Input
                  id="company_name"
                  defaultValue={companyData.name}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, name: e.target.value })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="company-website">Company website</Label>
                <Input
                  id="company_name"
                  defaultValue={companyData.website}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, website: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Company Email</Label>
                <Input
                  id="email"
                  defaultValue={companyData.email}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, email: e.target.value })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="phone">Company Phone</Label>
                <Input
                  id="phone"
                  defaultValue={companyData.phone}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="address">Address</Label>
              <Input
                id="address"
                defaultValue={companyData.address}
                onChange={(e) =>
                  setCompanyData({ ...companyData, address: e.target.value })
                }
              />
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end gap-6">
          <button
            className="px-6 py-2 rounded bg-destructive text-primary-foreground shadow"
            onClick={updateData}
          >
            Delete Company
          </button>
          <button
            className="px-6 py-2 rounded bg-primary text-primary-foreground shadow"
            onClick={updateData}
          >
            Update
          </button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default CompanyProfilePage;
