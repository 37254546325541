import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import BeatLoader from "react-spinners/BeatLoader";
import logo from "../../assets/logo.png";
import axiosInstance from "../../lib/apiconfig";
import Cookies from "js-cookie";

function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loginSuccessful, setLoginSuccessful] = useState(false);

  useEffect(() => {
    if (loginSuccessful) {
      navigate("/dashboard");
    }
  }, [loginSuccessful]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axiosInstance
      .post("login/", formData)
      .then((response) => {
        Cookies.set("access", response.data.access);
        Cookies.set("refresh", response.data.refresh);
        if (response.status === 200 && Cookies.get("access")) {
          setLoginSuccessful(true);
        }
      })
      .catch((error) => {
        alert("Error logging in, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="-mt-16 flex justify-center items-center">
        <img src={logo} alt="taskplus" width={180} height={180} />
      </div>
      <Card className="mx-auto w-1/4">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form method="post" onSubmit={handleSubmit}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  required
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                  <Link
                    to="/forgotpassword"
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <Input
                  id="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </div>
              <Button type="submit" className="w-full">
                {loading ? <BeatLoader size={8} color="#ffffff" /> : "Login"}
              </Button>
            </div>
          </form>
          <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{" "}
            <Link to="/register" className="underline">
              Sign up
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default LoginPage;
