import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  SIDEBAR_NAV_REALTIME,
  SIDEBAR_NAV_LISTUSERS,
  SIDEBAR_NAV_SETTINGS,
  SIDEBAR_NAV_USER,
} from "../../lib/menu";
import { getUser } from "../../lib/jwtdecode";

function SidebarComponent() {
  const user = getUser();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.admin) {
      setIsAdmin(user.admin);
    }
  }, [user]);

  return (
    <>
      {isAdmin ? (
        <div>
          <ul>
            <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
              Realtime
            </h2>
            {SIDEBAR_NAV_REALTIME.map((item, index) => (
              <li key={index}>
                <Link to={item.href}>
                  <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                    <span className="text-xl">{item.icon}</span>
                    <span>{item.title}</span>
                  </button>
                </Link>
              </li>
            ))}
          </ul>
          <ul>
            <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
              Users & Teams
            </h2>
            {SIDEBAR_NAV_LISTUSERS.map((item, index) => (
              <li key={index}>
                <Link to={item.href}>
                  <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                    <span className="text-xl">{item.icon}</span>
                    <span>{item.title}</span>
                  </button>
                </Link>
              </li>
            ))}
          </ul>
          <ul>
            <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
              Configuration
            </h2>
            {SIDEBAR_NAV_SETTINGS.map((item, index) => (
              <li key={index}>
                <Link to={item.href}>
                  <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                    <span>{item.title}</span>
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <ul>
            <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
              Dashboard
            </h2>
            {SIDEBAR_NAV_USER.map((item, index) => (
              <li key={index}>
                <Link to={item.href}>
                  <button className="px-6 py-2 w-full text-left text-sm font-medium hover:bg-blue-300 hover:text-primary">
                    <span>{item.title}</span>
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default SidebarComponent;
