import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "./apiconfig";

export const getUser = () => {
  const accessToken = Cookies.get("access");

  if (!accessToken || accessToken === "undefined") {
    console.error("Missing accessToken");
    return null;
  }
  try {
    const decodedToken = jwtDecode(accessToken);
    if (Date.now() >= decodedToken.exp * 1000) {
      refreshToken();
    }
    const user = decodedToken.user;
    return user;
  } catch (error) {
    console.error("Error decoding token", error);
    return null;
  }
};

const refreshToken = async () => {
  const refreshToken = Cookies.get("refresh");
  try {
    const response = await axiosInstance.post("auth/token/refresh/", {
      refresh: refreshToken,
    });
    if (response.data.access) {
      Cookies.set("access", response.data.access, { secure: true });
      Cookies.set("refresh", response.data.refresh, { secure: true });
      return true;
    } else {
      console.error("Refresh token failed");
      return false;
    }
  } catch (error) {
    console.error("Error refreshing token", error);
    return false;
  }
};
