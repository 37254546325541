import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../lib/apiconfig";
import EmployeeDailyWorkReport from "../../components/elements/employeereport";

function UserAnalyticsPage() {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get(`data/user/employee/${id}`);
      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
      <div className="flex justify-between items-center">
        <div className="">
          <h1 className="text-2xl font-semibold">
            {userData.first_name} {userData.last_name}
          </h1>
          <p className="text-base">{userData.designation}</p>
        </div>
        <div>
          <Link to={`/employeeprofile/${id}`}>
            <button className="bg-foreground px-8 py-2 text-white rounded-sm">
              View Profile
            </button>
          </Link>
        </div>
      </div>
      <div>
        <h2 className="text-2xl font-semibold">Working Report</h2>
        <div className="py-2"></div>
        <EmployeeDailyWorkReport />
      </div>
    </div>
  );
}

export default UserAnalyticsPage;
