import React, { useState, useEffect } from "react";
import axiosInstance from "../../lib/apiconfig";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { MdMoreHoriz } from "react-icons/md";
import { MdSearch } from "react-icons/md";

function ListCompaniesPage() {
  const [listCompanies, setListCompanies] = useState([]);

  useEffect(() => {
    const fetchCompaniesList = async () => {
      try {
        const response = await axiosInstance.get("v0/list/companies/");
        if (response.status === 200) {
          setListCompanies(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCompaniesList();
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
      <div className="w-full">
        <div className="flex items-center py-4">
          <Input placeholder="Filter by email" className="max-w-sm " />
          <MdSearch className="h-6 w-6 text-gray-500" />
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Website</TableHead>
                <TableHead>Phone</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {listCompanies.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.website}</TableCell>
                  <TableCell>{item.phone}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                          <span className="sr-only">Open menu</span>
                          <MdMoreHoriz className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem>
                          <Link to={`/company-profile/${item.uuid}`}>
                            View Profile
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Link to={`/employeeprofile/${item.uuid}`}>
                            View User List
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="text-red-800">
                          <button>Delete Company</button>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ListCompaniesPage;
